<template>
    <fragment>
        <el-button style="margin-left: 0;margin-right: 10px" type="primary" size="small" @click="add">新增</el-button>
        <el-button style="margin-left: 0;margin-right: 10px;margin-bottom: 10px" type="info" size="small" @click="clear">清空</el-button>
        <span class="warning-class" v-if="structure.warning" v-html="structure.warning"></span>
        <fragment v-if="showList">
            <div class="item" v-for="(item,index) in values"  :key="index" >
                <span @click="edit(index)">{{structure.label}} -- 数据集（{{index*1 + 1}}）</span>
                <el-button style="float:right;margin-left: 0;margin-right: 10px;margin-top: 10px" type="warning" size="small" @click="deleteValue(index)">删除</el-button>
            </div>
        </fragment>

        <a-modal   :title="structure.label" :style="{width:'40%'}" :closable="false"  v-model="isCollapse" >
            <div class="form-class" >
                <a-form-item  v-for="(child,childIndex) in valueStructure" :key="childIndex" :label="child.label">
                    <component
                            :key="timer + roundNumber + childIndex"
                            :is="'Lucky'+child.component"
                            :structure="child"
                    />
                </a-form-item>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="isCollapse = false">取 消</el-button>
                <el-button type="primary" @click="submit(handleIndex)">确认</el-button>
            </div>
        </a-modal>

    </fragment>
</template>

<script>

    import {
        LuckyInput,LuckyTextarea,LuckyNumber,LuckyRadio,LuckyCheckbox,LuckySelect
        ,LuckyDate,LuckyTime,LuckyCascader,LuckyTree,LuckyImage,LuckyImages,LuckyFile
        ,LuckySwitch
    } from "@/components/form-components"

    export default {
        name: "LuckyMore",
        props: ['structure','showWarning'],
        components: {
            LuckyInput,LuckyTextarea,LuckyNumber,LuckyRadio,LuckyCheckbox,LuckySelect
            ,LuckyDate,LuckyTime,LuckyCascader,LuckyTree,LuckyImage,LuckyImages,LuckyFile
            ,LuckySwitch
        },
        data(){
            return {
                timer:'',
                roundNumber:'',
                isCollapse:false,
                showList:false,
                values:{},
                valueStructure:{},
                handleIndex:-1
            }
        },
        mounted() {
            this.values = this.structure.value
            this.showList = true
        },
        methods:{
            clear(){
                this.$messageBox.confirm('确认清空所有数据集？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showList = false
                    this.values = {}
                    this.showList = true
                }).catch(action => {
                });
            },
            add(){
                this.valueStructure = JSON.parse(JSON.stringify(this.structure.config.children));
                this.timer = new Date().getTime()
                this.roundNumber = Math.floor(Math.random() * (9999 - 1 + 1)) + 1
                this.isCollapse = true
            },
            edit(i){
                this.handleIndex = i
                this.valueStructure = this.values[i];
                this.timer = new Date().getTime()
                this.roundNumber = Math.floor(Math.random() * (9999 - 1 + 1)) + 1
                this.isCollapse = true
            },
            submit(handleIndex){
                this.showList = false
                if(handleIndex == -1)
                    this.values.push(this.valueStructure)
                else
                    this.values[handleIndex] = this.valueStructure
                this.isCollapse = false
                this.valueStructure = {}
                this.handleIndex = -1
                this.showList = true
            },
            deleteValue(index){
                this.$messageBox.confirm('确认删除该数据集？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showList = false
                    this.values.splice(index,1);
                    this.showList = true
                }).catch(action => {
                });
            }



        }
    }
</script>

<style scoped>
    .item{
        overflow: hidden;
        z-index: 0;
        background-color: #fff;
        border: 1px solid #c0ccda;
        border-radius: 6px;
        box-sizing: border-box;
        padding-left: 20px;
        height: 54px;
        line-height: 54px;
        margin-bottom: 10px;
    }
    .form-class{
        max-height: 400px;
        overflow-y: auto;
        padding: 0 20px;
    }
</style>