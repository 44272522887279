<template>
    <fragment>
        <a-radio-group size="large"  v-model:value="structure.value">
            <a-radio @click="showChildrenItem(radio,radioIndex)" v-for="(radio,radioIndex) in structure.config.values" :key="radioIndex" :value="radio.value">
                {{radio.label}}
            </a-radio>
        </a-radio-group>


        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

        <div style="border: 1px solid gray;padding: 20px;border-radius: 5px" v-if="children.length > 0">
            <a-form-item  v-for="(child,childIndex) in children" :key="childIndex" :label="child.label">
                <component
                        :key="timer + roundNumber + childIndex"
                        :is="'Lucky'+child.component"
                        :structure="child"
                />
            </a-form-item>
        </div>

    </fragment>
</template>

<script>

    import {
        LuckyInput,LuckyTextarea,LuckyNumber,LuckyRadio,LuckyCheckbox,LuckyDate,LuckyTime,LuckyCascader,LuckyTree,LuckyImage,LuckyImages,LuckyFile,LuckySwitch,LuckySelect
    } from "@/components/form-components"
    export default {
        name: 'LuckyRadio',
        components: {
            LuckyInput,LuckyTextarea,LuckyNumber,LuckyRadio,LuckyCheckbox,LuckySelect,LuckyDate,LuckyTime,LuckyCascader,LuckyTree,LuckyImage,LuckyImages,LuckyFile,LuckySwitch
        },
        props: ['structure','showWarning'],
        data(){
            return {
                timer:'',
                roundNumber:'',
                nowItemIndex:'',  //当前子组建集的父级Index
                children:[] ,//子组建


            }
        },
        created() {
            if(this.structure.config.values.length >0)
            this.structure.config.values.forEach((item,index)=>{
                if(item.value == this.structure.value)
                {
                    this.showChildrenItem(item,index)
                }
            })
        },
        methods: {

            showChildrenItem(item=[],index=''){
                //查看是否有子组件
                this.children =[];
                this.nowItemIndex = '';
                if(item.hasOwnProperty("children")){
                    this.timer = new Date().getTime()
                    this.roundNumber = Math.floor(Math.random() * (9999 - 1 + 1)) + 1
                    this.nowItemIndex = index;
                    this.children = item.children;
                }
            },

        }
    }
</script>

<style scoped>

</style>