<template>
    <fragment>
        <el-button
                class="button-class"
                v-for="(button,buttonIndex) in structure.config"
                :type="button.buttonType"
                size="mini"
                @click="handle(button)"
                v-if="showButton(button)"
                :key="buttonIndex">
            {{ button.name }}
        </el-button>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyTableButtons',

        props: ['value','structure','rowValue'],
        methods: {
            handle(button){
                let that = this
                let obj = {}
                that.$set(obj,button.key,that.value);
                that.$parent.$parent.$parent.tableHandles(button,obj)
            },
            //根据数据判断按钮是否显示
            showButton(button){
                let that = this
                let showStatus = true;
                if(button.valueControl){
                    if(button.valueControl.rule == 'eq'){
                        if(button.valueControl.condition != that.rowValue[button.valueControl.field])
                            showStatus = false;
                    }
                    if(button.valueControl.rule == 'neq'){
                        if(button.valueControl.condition == that.rowValue[button.valueControl.field])
                            showStatus = false;
                    }
                    if(button.valueControl.rule == 'in'){
                        showStatus = false
                        button.valueControl.condition.forEach((item,index)=>{
                            if(item == that.rowValue[button.valueControl.field])
                                showStatus = true
                        })
                    }
                    if(button.valueControl.rule == 'out'){
                        button.valueControl.condition.forEach((item,index)=>{
                            if(item == that.rowValue[button.valueControl.field])
                                showStatus = false;
                        })
                    }
                }
                return showStatus;
            }
        }
    }
</script>

<style scoped>
    .button-class{
        margin-bottom: 5px;
        margin-left:0;
        margin-right:10px;
    }
</style>