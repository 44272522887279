<template>
    <el-image
            v-if="value"
            :style="structure.config.width + 'px'"
            :src="value"
            fit="contain"
            :preview-src-list="[value]">
    </el-image>
</template>

<script>
    export default {
        name:'LuckyTableImage',

        props: ['id','value','structure'],
    }
</script>

<style scoped>

</style>