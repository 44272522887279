<template>
    <fragment>

        <div style="margin-left: 10px;margin-bottom: 20px;">
            <el-date-picker
                    value-format="timestamp"
                    v-model="start_date"
                    clearable
                    :style="{width:'23.5%'}"
                    type="date"
                    placeholder="开始时间">
            </el-date-picker>
            <el-date-picker
                    value-format="timestamp"
                    v-model="end_date"
                    clearable
                    :style="{width:'23.5%',marginLeft:'20px'}"
                    type="date"
                    placeholder="结束时间">
            </el-date-picker>
            <el-button style="margin-left: 20px" type="primary" @click="indexStatistics()" size="medium">查询</el-button>
            <el-button style="margin-left: 20px" type="warning" @click="reset()" size="medium">重置</el-button>
        </div>
        <div style="width: 100%;height: 75vh;overflow-y: auto;padding-bottom: 100px">
        <div class="datas">
            <el-row style="margin-bottom: 20px">
                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-s-custom"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">总渠道数</div>
                            <div style="height: 40px;line-height: 30px">{{data.client_count}}</div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-s-marketing"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">总跟进项目数</div>
                            <div style="height: 40px;line-height: 30px">{{data.follow_up_item_count}}</div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-s-check"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">总签约数</div>
                            <div style="height: 40px;line-height: 30px">{{data.sign_item_count}}</div>
                        </div>
                    </el-card>
                </el-col>

            </el-row>
            <el-row style="margin-bottom: 20px">
                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-caret-top"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">总收入</div>
                            <div style="height: 40px;line-height: 30px">{{data.sr}}</div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-caret-bottom"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">总支出</div>
                            <div style="height: 40px;line-height: 30px">{{data.zc}}</div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="8" style="padding: 0 10px">
                    <el-card shadow="hover" style="height: 80px">
                        <div style="width: 35%;float: left;line-height: 80px;margin-top: 15px">
                            <i style="font-size: 50px;margin-left: 30px" class="el-icon-s-data"></i></div>
                        <div style="width: 55%;float: left;padding-left: 8%;text-align: center">
                            <div style="height: 40px;line-height: 50px">当前余额</div>
                            <div style="height: 40px;line-height: 30px">{{data.ye}}</div>
                        </div>
                    </el-card>
                </el-col>

            </el-row>
            <el-row style="margin-bottom: 10px">
                <el-col :span="12" style="padding: 0 20px">
                    <div style="width: 100%;height: 380px" id="main1"></div>
                </el-col>
                <el-col :span="12" style="padding: 0 20px">
                    <div style="width: 100%;height: 380px" id="main2"></div>
                </el-col>
            </el-row>
            <el-row style="margin-bottom: 10px">
                <el-col :span="24" style="padding: 0 20px">
                    <div style="width: 100%;height: 380px" id="main3"></div>
                </el-col>

            </el-row>
            <el-row style="margin-bottom: 10px">
                <el-col :span="24" style="padding: 0 20px">
                    <div style="width: 100%;height: 380px" id="main4"></div>
                </el-col>
            </el-row>
        </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name:'LuckyCompanyCharts',
        data(){
            return {
                data:[],
                start_date:'',
                end_date:'',
            }
        },
        created() {
            this.indexStatistics();
        },
        methods:{
            reset(){
                this.start_date = '';
                this.end_date = '';
                this.indexStatistics();
            },
            indexStatistics(){
                this.$ajax.post('/project/Account/statistics?auth_page_id=28',{start_date:this.start_date,end_date:this.end_date}).then(res=>{
                    this.data = res
                    this.myEcharts1(res);
                    this.myEcharts2(res);
                    this.myEcharts3(res);
                    this.myEcharts4(res);
                })
            },
            myEcharts1(res){
                let myChart = this.$echarts.init(document.getElementById('main1'));
                let option = {
                    title: {
                        text: '收入类型占比',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [
                        {
                            name: '单项收入',
                            type: 'pie',
                            radius: [30, 110],
                            center: ['50%', '50%'],
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 4
                            },
                            data: res.data1
                        }
                    ]
                };
                myChart.setOption(option);
            },
            myEcharts2(res){
                let myChart = this.$echarts.init(document.getElementById('main2'));
                let option = {
                    title: {
                        text: '支出类型占比',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [
                        {
                            name: '单项支出',
                            type: 'pie',
                            radius: [30, 110],
                            center: ['50%', '50%'],
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 4
                            },
                            data: res.data2
                        }
                    ]
                };
                myChart.setOption(option);
            },
            myEcharts3(res){
                let myChart = this.$echarts.init(document.getElementById('main3'));
                let option = {
                    title: {
                        text: '月收入',
                        left: 'center'
                    },
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: res.data3.keys,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '收入金额',
                            type: 'bar',
                            barWidth: '60%',
                            itemStyle: {
                                borderRadius: 4
                            },
                            data: res.data3.datas
                        }
                    ]
                };
                myChart.setOption(option);
            },
            myEcharts4(res){
                let myChart = this.$echarts.init(document.getElementById('main4'));
                let option = {
                    title: {
                        text: '月支出',
                        left: 'center'
                    },
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: res.data4.keys,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '支出金额',
                            type: 'bar',
                            barWidth: '60%',
                            itemStyle: {
                                borderRadius: 4
                            },
                            data: res.data4.datas
                        }
                    ]
                };
                myChart.setOption(option);
            },
        }
    }
</script>

<style >
    /*.el-card__body{*/
    /*    padding-top: 0;*/
    /*}*/
    .el-card{
        height: 110px !important;
    }
</style>