<template>
    <fragment>
        <el-row >
            <el-col :span="10" style="text-align:center;margin-top: 15vh;font-size: 40px;line-height: 100px">
                欢迎使用<br>
                {{system_name}}
            </el-col>
            <el-col :span="14">
                <div style="width: 100%;height: 40px;font-size: 26px;text-align: center;margin-bottom: 20px;margin-top: 10px">项目进度提醒<a-icon style="font-size: 20px;margin-left: 50px" type="redo" @click="reset()"/>
                </div>
                <div style="border:1px solid;border-radius:5px;width: 100%;height:60vh;overflow-y: auto">
                    <el-table :data="follow_up_datas" style="width: 100%;overflow-y: auto;padding-left:10px" >
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button  type="primary" @click="follow_up(scope.row['item_id'])" size="mini">跟进</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目ID" width="70">
                            <template slot-scope="scope">
                                {{scope.row['item_id']}}
                            </template>
                        </el-table-column>
                        <el-table-column label="项目名称" width="150">
                            <template slot-scope="scope">
                                {{scope.row['item_name']}}
                            </template>
                        </el-table-column>
                        <el-table-column label="跟进时间" width="100">
                            <template slot-scope="scope">
                                {{scope.row['next_follow_up_time']}}
                            </template>
                        </el-table-column>
                        <el-table-column label="客户ID" width="70">
                            <template slot-scope="scope">
                                {{scope.row['client_id']}}
                            </template>
                        </el-table-column>
                        <el-table-column label="联系人" width="100">
                            <template slot-scope="scope">
                                {{scope.row['client_name']}}
                            </template>
                        </el-table-column>
                        <el-table-column label="联系方式" >
                            <template slot-scope="scope">
                                {{scope.row['client_connect']}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
    </fragment>
</template>

<script>

    export default {
        name:'LuckyIndex',
        data(){
            return {

                system_logo:'logo.png',                  //项目LOGO
                system_name:'Lucky3.0',                  //项目名称
                follow_up_datas:[],
            }
        },
        created() {
            this.$ajax.post('/auth/Admin/afterLogin', {})
                .then(res=>{
                    this.system_name = res.system_name?res.system_name:'Lucky2.0';
                    this.system_logo = res.system_logo;
                })
            this.$ajax.post('/project/FollowUp/index', {})
                .then(res=>{
                    this.follow_up_datas = res;
                })
        },
        methods:{
            reset(){
                let that =this

                that.$ajax.post('/project/FollowUp/index', {})
                    .then(res=>{
                        that.follow_up_datas = res;
                    })
            },
            follow_up(id){
                let that =this
                that.$ajax.post('/project/FollowUp/follow_up', {id:id})
                    .then(res=>{
                        that.$ajax.post('/project/FollowUp/index', {})
                            .then(res=>{
                                that.follow_up_datas = res;
                            })
                    })
            }
        }
    }
</script>

<style >
    /*.el-card__body{*/
    /*    padding-top: 0;*/
    /*}*/
    .el-card{
        height: 110px !important;
    }
</style>